import socketIOClient from 'socket.io-client';
import { useEffect, useState, useRef } from 'react';
import config from '~/config';
import axios from 'axios';
import { AiOutlineSend } from 'react-icons/ai';
import HeaderChatArea from './HeaderChatArea';
import styles from './Chat.module.scss';
import classNames from 'classnames/bind';
import AddConversation from './AddConversation';
import { socketStatus, socketMessage } from '~/utils/socket';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

function ChatArea() {
   const [mess, setMess] = useState([]);
   const [message, setMessage] = useState('');
   const [conversationId, setConversationId] = useState();
   const [user, setUser] = useState();
   const [activeStatusFriend, setActiveStatusFriend] = useState(true);
   const [loading, setLoading] = useState(true);

   const socketRef = useRef();
   const messagesEnd = useRef();

   const navigate = useNavigate();

   useEffect(() => {
      const theUser = JSON.parse(localStorage.getItem('user'));
      if (theUser && theUser.token && theUser._id) {
         setUser(theUser);

         axios
            .get(`${config.api.url}/chat`, { headers: { Authorization: `Bearer ${theUser.token}` } })
            .then((res) => {
               setLoading(false);
               setConversationId(res.data.conversation._id);
               setMess(res.data.messages.reverse());
               scrollToBottom();

               socketRef.current = socketIOClient.connect(`${config.api.socket}`);
               socketRef.current.emit(socketStatus.joinRoom, conversationId);

               socketRef.current.on(socketStatus.receiveMessage, (dataGot) => {
                  setMess((oldMsgs) => [...oldMsgs, dataGot]);
                  scrollToBottom();
               });
               socketRef.current.on(socketStatus.receiveNoti, (dataGot) => {
                  if (dataGot === socketMessage.userLeft) {
                     setActiveStatusFriend(false);
                  } else if (dataGot === socketMessage.userJoined) {
                     setActiveStatusFriend(true);
                  }
               });
            })
            .catch((err) => {
               console.log(err);
               setLoading(false);
            });
      } else {
         navigate(config.routes.login);
      }

      return () => {
         if (socketRef && socketRef.current) {
            socketRef.current.disconnect();
         }
      };
   }, [conversationId, navigate]);

   const sendMessage = () => {
      if (message !== null) {
         const msg = {
            content: message,
            sender: user._id,
         };
         socketRef.current.emit(socketStatus.sendMessage, conversationId, msg);
         setMessage('');
      }
   };

   const scrollToBottom = () => {
      messagesEnd.current.scrollIntoView({ behavior: 'smooth' });
   };

   const handleChange = (e) => {
      setMessage(e.target.value);
   };

   const onEnterPress = (e) => {
      // eslint-disable-next-line
      if (e.keyCode == 13 && e.shiftKey == false) {
         sendMessage();
      }
   };

   return (
      <div className={cx('wrapper')}>
         {loading ? (
            <div className={cx('loading')}>Loading...</div>
         ) : (
            <>
               {conversationId ? (
                  <>
                     <HeaderChatArea activeStatus={activeStatusFriend} />
                     <div className={cx('boxchat')}>
                        {mess.map((m, index) => (
                           <div key={index} className={m.sender === user._id ? cx('yourmessage') : cx('otherpeople')}>
                              {m.content}
                           </div>
                        ))}
                        <div className={cx('ref-message')} ref={messagesEnd}></div>
                     </div>

                     <div className={cx('sendbox')}>
                        <div className={cx('sendmessagebox')}>
                           <input
                              className={cx('textarea')}
                              value={message}
                              onKeyDown={onEnterPress}
                              onChange={handleChange}
                              placeholder="Aa"
                           />

                           <button className={cx('buttonsend')} onClick={sendMessage}>
                              <AiOutlineSend />
                           </button>
                        </div>
                     </div>
                  </>
               ) : (
                  <AddConversation user={user} />
               )}
            </>
         )}
      </div>
   );
}

export default ChatArea;
